<template>
  <loader v-if="isLoading" message="Loading ...." />
  <app-logs v-else :logs="logs" />
</template>
<script>
import { mapGetters } from 'vuex'
import AppLogs from '@/components/apps/AppLogs'
import Loader from '@/components/cards/Loader'
import { appLogsIOKey } from '@/utils/ioConstants'
import { SimpleEventBus } from '@/services/core/EventBus'
export default {
  name: 'AppLogs',
  components: {
    'app-logs': AppLogs,
    loader: Loader,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  created() {
    SimpleEventBus.$on(appLogsIOKey, (data) => {
      if (data === 'closed' || data === 'opened') {
        return
      }
      this.logs.push(data)
    })
  },
  computed: {
    ...mapGetters({
      selectedEnvironment: 'app/currentAppEnv',
    }),
  },
  data() {
    return {
      logs: [],
      isLoading: false,
    }
  },
  methods: {
    getCurrentLogs() {
      const jwt = localStorage.getItem('jwt')
      this.$store
        .dispatch('app/initGetAppLogWebsocket', {
          app_id: this.id,
          environment: this.selectedEnvironment,
        })
        .then((socket) => {
          socket.queue(jwt)
        })
    },
  },
  watch: {
    selectedEnvironment: {
      handler() {
        this.logs = []
        this.getCurrentLogs()
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped></style>
